import React from "react";
import preval from "preval.macro";
import Moment from "react-moment";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "gatsby";
import { menu } from "../data/menus";
import { Logo } from "./fa";

const buildDate = preval`module.exports = new Date().toLocaleString()`;

const Layout = ({ children, signOut, user }) => (
  <>
    <header>
      <div className="header p-3 p-lg-5 pb-lg-3 bg-primary text-white">
        <div className="text-center">
          <Logo
            className="d-inline display-1"
            style={{ width: "120px" }}
            icon="heart-pulse"
          />
          <div className="d-inline display-3 align-top "> Monitoring</div>
        </div>
        <hr />
        <Navbar expand="md" variant="dark">
          <Navbar.Brand href="/">Monitoring</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {menu.map(({ title, path }) => (
                <span>
                  <Link className="nav-link" to={path}>
                    {title}
                  </Link>
                </span>
              ))}

              <button
                className="btn btn-sm btn-outline-light"
                onClick={signOut}
              >
                Sign out {user.username}
              </button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
    <main>
      <div className="bg-light p-3 p-lg-5">
        <div>{children}</div>
      </div>
    </main>
    <footer>
      <div className="small p-3 text-center text-white">
        <div>
          <b>
            &copy; exsertus.com <Moment format="YYYY">{buildDate}</Moment>
          </b>
        </div>
        <div className="small text-warning">
          Build Date:
          <Moment format="MMMM Do YYYY, HH:mm:ss">{buildDate}</Moment>
        </div>
      </div>
    </footer>
  </>
);

export default Layout;
