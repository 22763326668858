import React from "react";
import Layout from "./src/components/layout";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator, View, useTheme } from "@aws-amplify/ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "@popperjs/core/dist/umd/popper.min";
import "/src/style/global.css";
import "@aws-amplify/ui-react/styles.css";
import { Logo } from "./src/components/fa";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View className="m-5" textAlign="center" padding={tokens.space.large}>
        <div className="text-light">
          <Logo
            className="d-inline display-1"
            style={{ width: "100px", height: "75px" }}
            icon="heart-pulse"
          />
          <div className="d-inline display-3 align-top "> Monitoring</div>
        </div>
      </View>
    );
  },
};

export const wrapPageElement = ({ element, props }) => (
  <Authenticator
    components={components}
    socialProviders={["facebook"]}
    hideSignUp={true}
  >
    {({ signOut, user }) => (
      <Layout signOut={signOut} user={user} {...props}>
        {element}
      </Layout>
    )}
  </Authenticator>
);

export const wrapRootElement = ({ element }) => {
  const config = {
    Auth: {
      mandatorySignIn: true,
      region: "eu-west-1",
      userPoolId: process.env.GATSBY_POOL_ID,
      userPoolWebClientId: process.env.GATSBY_CLIENT_ID,
      oauth: {
        domain: process.env.GATSBY_AUTH_DOMAIN,
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn:
          process.env.NODE_ENV === "production"
            ? process.env.GATSBY_SITE_URL
            : "http://localhost:8000",
        redirectSignOut:
          process.env.NODE_ENV === "production"
            ? process.env.GATSBY_SITE_URL
            : "http://localhost:8000",
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "exsertus",
          endpoint: "https://api.exsertus.com",
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  };

  Amplify.configure(config);

  return element;
};
